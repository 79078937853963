// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-page .card {
  width: 400px;
  border-radius: 5px;
  padding: 10px 25px;
  background-color: rgb(128, 37, 36);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 30px;
}

.login-page .button {
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Page/LoginPage/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,kCAAkC;EAClC,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,oBAAoB;AACtB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".login-page {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n}\n\n.login-page .card {\n  width: 400px;\n  border-radius: 5px;\n  padding: 10px 25px;\n  background-color: rgb(128, 37, 36);\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  padding-bottom: 30px;\n}\n\n.login-page .button {\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
