import { Box, Flex, Text } from "@chakra-ui/react";
import Navbar from "../../Component/Navbar";

const HomePage = () => {
  return (
    <Box
      as="main"
      bg="rgb(84, 33, 28)"
      minH="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Navbar />
      <Flex id="hero-section" bgGradient="linear(to-b, rgba(0, 0, 0, 0.4), rgb(84, 33, 28))" direction="column" alignItems="center" justifyContent="space-around" pb="10" pt="100px" overflow="hidden" h="100vh" w="100%">
        <Flex direction={{ base: "column", xl: "row"}} px="50px" gap="50px">
            <Text fontSize={{ base: "xl", md: "2xl", xl: "4xl"}} fontWeight="bold" color="rgb(228,163,83)" textAlign={{ base: "center", xl: "left" }}>Thank you for your payment</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default HomePage;
