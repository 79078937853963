import { Box, Heading, Text, Link, Container } from "@chakra-ui/react";
import logo from "../../Assets/logo.png";

const PrivacyPage = () => {
    return (
        <Box
            as="main"
            bg="rgb(84, 33, 28)"
            color="white"
            minH="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={10}
            px={4}
        >
            <Container maxW={{ base: "90%", md: "800px" }} bg="rgba(0, 0, 0, 0.5)" p={{ base: 6, md: 10 }} borderRadius="md" boxShadow="lg">
                <Heading as="h1" fontSize={{ base: "2xl", md: "3xl" }} mb={4} textAlign="center" color="rgb(228,163,83)">
                    Privacy Policy for Kompas Kuliner
                </Heading>
                <Text textAlign="center" fontSize={{ base: "sm", md: "md" }} mb={4}>
                    Effective Date: 12 September 2024
                </Text>

                <Box display="flex" justifyContent="center" my={4}>
                    <img src={logo} alt="logo" width="100px" />
                </Box>

                <Box as="section" mt={4}>
                    <Heading as="h2" fontSize={{ base: "lg", md: "xl" }} mb={2} color="rgb(228,163,83)">
                        1. Introduction
                    </Heading>
                    <Text fontSize={{ base: "sm", md: "md" }}>
                        This Privacy Policy outlines how PT. Kompas Kuliner Internasional collects, uses, and protects your personal information when you use our mobile application, Kompas Kuliner. Your privacy is important to us, and we are committed to safeguarding your personal information.
                    </Text>
                </Box>

                <Box as="section" mt={4}>
                    <Heading as="h2" fontSize={{ base: "lg", md: "xl" }} mb={2} color="rgb(228,163,83)">
                        2. Information We Collect
                    </Heading>
                    <Text fontSize={{ base: "sm", md: "md" }}>
                        We may collect the following types of personal information from you when you use our app:
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        <strong>Personal Information:</strong> Name, email address, phone number, and other contact information you provide when you create an account or interact with our app.
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        <strong>Usage Data:</strong> Information about how you use our app, such as your IP address, device type, operating system, browser type, and usage history.
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        <strong>Location Information:</strong> If you allow our app to access your location, we may collect your approximate location.
                    </Text>
                </Box>

                <Box as="section" mt={4}>
                    <Heading as="h2" fontSize={{ base: "lg", md: "xl" }} mb={2} color="rgb(228,163,83)">
                        3. How We Use Your Information
                    </Heading>
                    <Text fontSize={{ base: "sm", md: "md" }}>
                        We may use your personal information for the following purposes:
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        To provide and maintain our app.
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        To personalize your experience and provide tailored content.
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        To communicate with you about our app, updates, and promotions.
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        To analyze and improve our app's performance.
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        To comply with legal obligations.
                    </Text>
                </Box>

                <Box as="section" mt={4}>
                    <Heading as="h2" fontSize={{ base: "lg", md: "xl" }} mb={2} color="rgb(228,163,83)">
                        4. Sharing Your Information
                    </Heading>
                    <Text fontSize={{ base: "sm", md: "md" }}>
                        We may share your personal information with:
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        <strong>Third-party service providers:</strong> We may engage third-party service providers to help us with various aspects of our business, such as data analysis, marketing, and customer support.
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        <strong>Legal requirements:</strong> We may disclose your personal information to comply with applicable laws, regulations, or legal processes.
                    </Text>
                </Box>

                <Box as="section" mt={4}>
                    <Heading as="h2" fontSize={{ base: "lg", md: "xl" }} mb={2} color="rgb(228,163,83)">
                        5. Data Security
                    </Heading>
                    <Text fontSize={{ base: "sm", md: "md" }}>
                        We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
                    </Text>
                </Box>

                <Box as="section" mt={4}>
                    <Heading as="h2" fontSize={{ base: "lg", md: "xl" }} mb={2} color="rgb(228,163,83)">
                        6. Contact Us
                    </Heading>
                    <Text fontSize={{ base: "sm", md: "md" }}>
                        If you have any questions, please contact us at:
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        <strong>PT. Kompas Kuliner Internasional</strong>
                    </Text>
                    <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                        <strong>Email:</strong>{" "}
                        <Link href="mailto:admin@kompaskuliner.com" color="teal.200">
                            admin@kompaskuliner.com
                        </Link>
                    </Text>
                </Box>
            </Container>
        </Box>
    );
};

export default PrivacyPage;
