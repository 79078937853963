// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: rgb(252, 249, 247);
  font-family: "Poppins", sans-serif !important;
}

h2 {
  color: white;
}

h3 {
  color: rgb(124, 49, 41);
  margin: 0;
}

.label {
  color: white;
  font-size: small;
}

.maps-icon i {
  color: black;
  font-size: 20px;
}

.youtube-icon i {
  color: red;
  font-size: 20px;
}

.field {
  margin-bottom: 14px;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,oCAAoC;EACpC,6CAA6C;AAC/C;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap\");\n\nbody {\n  background-color: rgb(252, 249, 247);\n  font-family: \"Poppins\", sans-serif !important;\n}\n\nh2 {\n  color: white;\n}\n\nh3 {\n  color: rgb(124, 49, 41);\n  margin: 0;\n}\n\n.label {\n  color: white;\n  font-size: small;\n}\n\n.maps-icon i {\n  color: black;\n  font-size: 20px;\n}\n\n.youtube-icon i {\n  color: red;\n  font-size: 20px;\n}\n\n.field {\n  margin-bottom: 14px;\n}\n\n.swiper {\n  width: 100%;\n  padding-top: 50px;\n  padding-bottom: 50px;\n}\n\n.swiper-slide {\n  background-position: center;\n  background-size: cover;\n  width: 300px;\n  height: 300px;\n}\n\n.swiper-slide img {\n  display: block;\n  width: 100%;\n}\n\n@keyframes bounce {\n  0%, 20%, 50%, 80%, 100% {\n    transform: translateY(0);\n  }\n  40% {\n    transform: translateY(-10px);\n  }\n  60% {\n    transform: translateY(-5px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
