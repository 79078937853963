import { createBrowserRouter } from 'react-router-dom';
import AdminPage from '../Page/AdminPage/AdminPage';
import Floggers from '../Page/AdminPage/Floggers/Floggers';
import Reviews from '../Page/AdminPage/Reviews/Reviews';
import Users from '../Page/AdminPage/Users/Users';
import HomePage from '../Page/HomePage/HomePage';
import LoginPage from '../Page/LoginPage/LoginPage';
import SuccessPage from '../Page/SuccessPage/SuccessPage';
import PrivacyPage from '../Page/PrivacyPage/PrivacyPage';

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <HomePage />
        ),
    },
    {
        path: '/success',
        element: (
            <SuccessPage />
        ),
    },
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        path: '/admin',
        element: <AdminPage />,
    },
    {
        path: '/users',
        element: <Users />
    },
    {
        path: '/floggers',
        element: <Floggers />
    },
    {
        path: '/reviews',
        element: <Reviews />
    },
    {
        path: '/privacy',
        element: <PrivacyPage />
    }
]);

export default router;