import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { motion } from "framer-motion";
import { FaAppStoreIos, FaEnvelope, FaGooglePlay, FaInstagram } from 'react-icons/fa';
import country from '../../Assets/1.gif';
import location from '../../Assets/2.gif';
import anakKuliner from "../../Assets/floggers/anak-kuliner.png";
import bangMpin from "../../Assets/floggers/bang-mpin.png";
import boengkoes from "../../Assets/floggers/boengkoes.png";
import budionoSukses from "../../Assets/floggers/budiono-sukses.png";
import dyodoran from "../../Assets/floggers/dyodoran.png";
import faridaNurhan from "../../Assets/floggers/farida-nurhan.png";
import hobbyMakan from "../../Assets/floggers/hobby-makan.png";
import kenGrat from "../../Assets/floggers/ken-n-grat.png";
import mgdalenaf from "../../Assets/floggers/mgdalenaf.png";
import nexCarlos from "../../Assets/floggers/nex-carlos.png";
import riaSw from "../../Assets/floggers/ria-sw.png";
import separuhAkuLemak from "../../Assets/floggers/separuh-aku-lemak.png";
import tanboyKun from "../../Assets/floggers/tanboy-kun.png";
import jwestbros from '../../Assets/floggers/jwestbros.png';
import bestEverFoodReviewShow from '../../Assets/floggers/best-ever-food-review-show.png';
import buzzfeedvideo from '../../Assets/floggers/buzzfeedvideo.png';
import jolly from '../../Assets/floggers/jolly.png';
import kubiler from '../../Assets/floggers/kubiler.png';
import markWiens from '../../Assets/floggers/mark-wiens.png';
import mikeChen from '../../Assets/floggers/mike-chen.png';
import moreBestEverFoodReviewShow from '../../Assets/floggers/more-best-ever-food-review-show.png';
import subscription from '../../Assets/subscription.gif';
import Navbar from "../../Component/Navbar";
import Screenshots from "../../Component/Screenshots";

const data = [
  {
    image: anakKuliner,
    name: "Anak Kuliner"
  },
  {
    image: bangMpin,
    name: "Bang Mpin"
  },
  {
    image: boengkoes,
    name: "Boengkoes"
  },
  {
    image: budionoSukses,
    name: "BudionoSukses"
  },
  {
    image: dyodoran,
    name: "Dyodoran"
  },
  {
    image: faridaNurhan,
    name: "Farida Nurhan"
  },
  {
    image: hobbyMakan,
    name: "Hobby Makan"
  },
  {
    image: kenGrat,
    name: "Ken n Grat"
  },
  {
    image: mgdalenaf,
    name: "Mgdalenaf"
  },
  {
    image: nexCarlos,
    name: "Nex Carlos"
  },
  {
    image: riaSw,
    name: "Ria SW"
  },
  {
    image: separuhAkuLemak,
    name: "Separuh Aku Lemak"
  },
  {
    image: tanboyKun,
    name: "Tanboy Kun"
  },
  {
    image: jwestbros,
    name: "JWestBros"
  },
  {
    image: bestEverFoodReviewShow,
    name: "Best Ever Food Review Show"
  },
  {
    image: buzzfeedvideo,
    name: "BuzzFeedVideo"
  },
  {
    image: jolly,
    name: "JOLLY"
  },
  {
    image: kubiler,
    name: "KUBILER"
  },
  {
    image: markWiens,
    name: "Mark Wiens"
  },
  {
    image: moreBestEverFoodReviewShow,
    name: "More Best Ever Food Review Show"
  },
  {
    image: mikeChen,
    name: "Mike Chen"
  },
];

const HomePage = () => {
  const youtubers = [...data, ...data, ...data, ...data];
  const glow = keyframes`
    0% { text-shadow: 0 0 5px rgb(228,163,83), 0 0 10px rgb(228,163,83), 0 0 20px rgb(228,163,83); }
    50% { text-shadow: 0 0 10px rgb(228,163,83), 0 0 20px rgb(228,163,83), 0 0 30px rgb(228,163,83); }
    100% { text-shadow: 0 0 5px rgb(228,163,83), 0 0 10px rgb(228,163,83), 0 0 20px rgb(228,163,83); }
  `;

  const handleLearnMoreClick = () => {
    const target = document.getElementById("feature-section");
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      as="main"
      bg="rgb(84, 33, 28)"
      minH="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Navbar />
      {/* Hero Section */}
      <Flex id="hero-section" bgGradient="linear(to-b, rgba(0, 0, 0, 0.4), rgb(84, 33, 28))" direction="column" alignItems="center" justifyContent="space-around" pb="10" pt="100px" overflow="hidden" h="100vh" w="100%">
        <Flex direction={{ base: "column", xl: "row"}} px="50px" gap="50px">
          <Flex flex="1" justifyContent="center" alignItems="center">
            <Screenshots />
          </Flex>
          <Flex flex="1" direction="column" justifyContent="center" alignItems={{ base: "center" , xl: "flex-start" }}>
            <Text fontSize={{ base: "lg", md: "xl", xl: "3xl"}} color="white">We connect</Text>
            <Text fontSize={{ base: "2xl", md: "3xl", xl: "6xl"}} fontWeight="bold" color="rgb(228,163,83)">FOODIES</Text>
            <Text fontSize={{ base: "lg", md: "xl", xl: "3xl"}} color="white">and</Text>
            <Text fontSize={{ base: "2xl", md: "3xl", xl: "6xl"}} fontWeight="bold" color="rgb(228,163,83)" textAlign={{ base: "center", xl: "left" }}>FOOD VLOGGERS</Text>
            <Text fontSize={{ base: "lg", md: "xl", xl: "3xl"}} color="white" textAlign={{ base: "center", xl: "left" }}>through interactive maps</Text>
            {/* <Flex direction="column" mt="20px">
              <Text fontSize="2xl" fontWeight="bold" color="rgb(228,163,83)">
                Coming Soon
              </Text>
              <Flex direction="row" gap="5px" alignItems="center">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  boxSize="40px"
                  bgColor="rgb(149, 171, 99)"
                  borderRadius="full"
                >
                  <Icon as={FaAndroid} boxSize="20px" color="white" />
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  boxSize="40px"
                  bgColor="rgb(149, 171, 99)"
                  borderRadius="full"
                >
                  <Icon as={FaApple} boxSize="20px" color="white" />
                </Flex>
              </Flex>
            </Flex> */}
          </Flex>
        </Flex>
        <Text
          onClick={handleLearnMoreClick}
          color="rgb(228,163,83)"
          _hover={{ color: "rgb(149,171,99)", cursor: "pointer" }}
          fontSize={{ base: "sm", md: "lg" }}
          display="flex"
          alignItems="center"
          animation="bounce 1.5s infinite"
        >
          Learn More
          <Icon as={ChevronDownIcon} boxSize={6} ml={2} />
        </Text>
      </Flex>

      {/* Features Section */}
      <Flex
        bg="rgb(252, 250, 248)"
        width="100%"
        px="50px"
        h={{ base: "100%", xl: "100vh"}}
        justifyContent="center"
        alignItems="center"
        direction="column"
        gap="50px"
        id="feature-section"
        pb="50px"
      >
        <Flex pt={{ base: "100px", sm: "50px" }} width={{ base: "80%", md: "60%" }} direction="column" justifyContent="center" alignItems="center">
          <Text fontSize="2xl" fontWeight="bold" mb="10px">What we have</Text>
          <Text fontSize="lg" fontWeight="500" textAlign="center">
            At <span style={{ color: "rgb(124, 49, 41)", fontWeight: "bold" }}>Kompas Kuliner</span>, we're always updating our content to bring you fresh food experiences. Right now, you can explore a wide selection of food vloggers and locations, and we’re constantly adding more so you can keep discovering new places and creators.
          </Text>
        </Flex>
        <Flex
          direction={{ base: "column", xl: "row"}}
          gap="30px"
        >
          <Flex
            w="350px"
            h="400px"
            bg="rgb(84, 33, 28)"
            borderTopRightRadius="25px"
            borderBottomLeftRadius="25px"
            p={7}
            justifyContent="flex-end"
            alignItems="center"
            textAlign="center"
            direction="column"
          >
            <Flex justifyContent="center" alignItems="center" mb={4} overflow="hidden">
              <img 
                src={location} 
                alt="Exploring Locations" 
                style={{ 
                  width: '100%',
                  objectFit: 'cover',
                  objectPosition: 'bottom'
                }} 
              />
            </Flex>
            <Flex direction="column" gap="10px">
              <Text color="rgb(252, 250, 248)" fontSize="lg" fontWeight="bold">10.000+ Locations to Explore</Text>
              <Text color="rgb(252, 250, 248)" fontSize="md">With over 10.000 food spots from over 10.000 videos in our app, you’ll always have a new place to try. From street food to fine dining, there’s something for everyone.</Text>
            </Flex>
          </Flex>
          <Flex
            w="350px"
            h="400px"
            bg="rgba(205, 147, 75, 1)"
            borderTopRightRadius="25px"
            borderBottomLeftRadius="25px"
            p={7}
            justifyContent="space-around"
            alignItems="center"
            textAlign="center"
            direction="column"
            overflow="hidden"
          >
            <Flex direction="column" gap="10px">
              <Text color="rgb(252, 250, 248)" fontSize="lg" fontWeight="bold">20+ Food Vloggers from YouTube</Text>
              <Text color="rgb(252, 250, 248)" fontSize="md">We feature over 20 popular food vloggers from YouTube. If you have a favorite, you can request them, and we'll try to add them to our collection.</Text>
            </Flex>
            <motion.div
              style={{ display: "flex", gap: "15px", overflow: "hidden" }}
              animate={{ x: "-50%" }}
              transition={{ duration: 35, ease: "linear", repeat: Infinity, repeatDelay: 0 }}
            >
              {youtubers.map((youtuber, index) => (
                <Box
                  key={index}
                  w="70px"
                  h="70px"
                  borderRadius="10px"
                  overflow="hidden"
                >
                  <img src={youtuber.image} alt={youtuber.name} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                </Box>
              ))}
            </motion.div>
          </Flex>
          <Flex
            w="350px"
            h="400px"
            bg="rgba(129, 148, 89, 1)"
            borderTopRightRadius="25px"
            borderBottomLeftRadius="25px"
            p={7}
            justifyContent="flex-end"
            alignItems="center"
            textAlign="center"
            direction="column"
          >
            <Flex justifyContent="center" alignItems="center" mb={4} overflow="hidden">
              <img 
                src={country} 
                alt="Exploring Countries" 
                style={{ 
                  width: '100%',
                  objectFit: 'cover',
                  objectPosition: 'bottom'
                }} 
              />
            </Flex>
            <Flex direction="column" gap="10px">
              <Text color="rgb(252, 250, 248)" fontSize="lg" fontWeight="bold">7+ Countries Featured</Text>
              <Text color="rgb(252, 250, 248)" fontSize="md">We already have food locations from 7+ countries, including Indonesia, and we're adding more from various Youtuber to give you a global food experience.</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        bg="black"
        width="100%"
        px="50px"
        h={{ base: "100%", xl: "100vh"}}
        justifyContent="center"
        alignItems="center"
        direction={{ base: "column", xl: "row" }}
        gap="50px"
        id="feature-section"
        py="100px"
      >
        <Flex flex={1} justifyContent="center">
          <Flex width="100%">
            <img src={subscription} alt="Subscription" width="90%"/>
          </Flex>
        </Flex>
        <Flex direction="column" gap="20px" flex={2} color="white" textAlign="center">
          <Flex direction="column" gap="5px">
            <Text fontSize="2xl" color="rgb(228,163,83)" fontWeight="bold">
              2 Plans Available!
            </Text>
            <Text fontSize="lg" fontWeight="bold">
              You'll have ONE unlocked food vlogger for FREE for a lifetime, but if you want more ..
            </Text>
          </Flex>
          <Flex direction={{ base: "column", xl: "row" }} gap="20px">
            {/* Monthly Plan */}
            <Flex direction="column" gap="10px" border="1px solid rgb(124, 49, 41)" borderRadius="md" p="20px" flex={1}>
                <Text fontSize="xl" color="rgb(124, 49, 41)" fontWeight="bold">
                  Monthly
                </Text>
                <Text fontSize="md" color="rgb(228,163,83)" fontWeight="bold">
                  Rp 29.900,00
                </Text>
                <Text fontSize="md">
                  Gain full access to all food vloggers for <br />
                  <b>ONE MONTH</b>.
                </Text>
              </Flex>

            {/* Yearly Plan */}
            <Flex direction="column" gap="10px" border="1px solid rgb(124, 49, 41)" borderRadius="md" p="20px" flex={1}>
                <Text fontSize="xl" color="rgb(124, 49, 41)" fontWeight="bold">
                  Yearly
                </Text>
                <Text fontSize="md" color="rgb(228,163,83)" fontWeight="bold">
                  Rp 199.900,00
                </Text>
                <Text fontSize="md">
                  Enjoy the same benefits as the monthly plan for <br />
                  <b>ONE YEAR</b>.
                </Text>
            </Flex>
          </Flex>
          <Text fontSize="md" fontWeight="bold">
            We're constantly working to bring you exciting
            new features and updates in the future.
          </Text>
          <Text
            fontSize="xl"
            color="rgb(228,163,83)"
            fontWeight="bold"
            animation={`${glow} 2s infinite`}
          >
            This is just the beginning!
          </Text>
        </Flex>
      </Flex>

      {/* Download Section */}
      <Flex
        bgGradient="linear(to-t, rgba(0, 0, 0, 0.7), rgb(84, 33, 28))"
        width="100%"
        px="50px"
        h="100vh"
        justifyContent="center"
        alignItems="center"
        direction="column"
        gap="50px"
        id="download-section"
        pt={{ base: "100px", sm: "50px" }}
        pb="70px"
      >
        {/* Heading: Coming Soon */}
        <Text 
          fontSize={{ base: "4xl", md: "6xl"}} 
          color="rgb(228,163,83)" 
          fontWeight="bold" 
          textAlign="center"
        >
          Our Mobile App is Coming Soon!
        </Text>

        {/* Subheading: Find us on iOS and Android */}
        <Text 
          fontSize={{ base: "xl", md: "2xl" }}
          color="rgb(149, 171, 99)" 
          textAlign="center"
        >
          Will be available on iOS and Android
        </Text>

        <Flex gap="30px" justifyContent="center">
          <FaAppStoreIos 
            size={75} 
            color="white" 
          />
          <FaGooglePlay 
            size={75} 
            color="white" 
          />
        </Flex>

        <Flex direction="column" alignItems="center" gap="15px">
          <Text color="rgb(149, 171, 99)" fontSize="lg" textAlign="center">
            Where to Find Us:
          </Text>

          <Flex direction="column" gap="5px" alignItems="flex-start">
            {/* Email */}
            <Flex alignItems="center" gap="10px" justifyContent="center">
              <FaEnvelope size={20} color="rgb(228,163,83)" />
              <a href="mailto:admin@kompaskuliner.com" style={{ color: 'white', fontSize: 'md' }}>admin@kompaskuliner.com</a>
            </Flex>

            {/* Instagram */}
            <Flex alignItems="center" gap="10px" justifyContent="center">
              <FaInstagram size={20} color="rgb(228,163,83)" />
              <a href="https://www.instagram.com/kompas_kuliner" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: 'md' }}>@kompaskuliner.id</a>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default HomePage;
