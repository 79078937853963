// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    padding: 20px 40px;
}

.header, .header .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .right {
    gap: 10px;
}

.youtube-channel {
    display: flex;
    gap: 7px;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Page/AdminPage/AdminPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;AACvB","sourcesContent":[".content {\n    padding: 20px 40px;\n}\n\n.header, .header .right {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.header .right {\n    gap: 10px;\n}\n\n.youtube-channel {\n    display: flex;\n    gap: 7px;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
